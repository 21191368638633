<template>
  <div class="card-home" :style="'height: '+pageHeight+'px'">
    <div class="btn-back" @click="$router.push('/home')"><img
      src="http://pic.eaworks.cn/scbank/uploads/202283/7246025.png" alt=""></div>
    <div class="card-wrapper" v-if="currCardDetail">
      <div class="card-banner" @click="onClickPageLink(currCardDetail.head_link)">
        <img :src="currCardDetail.banner" alt="卡片">
      </div>
      <div class="card-menu" style="padding: 0">
        <img :src="currCardDetail.menu_bg" alt="菜单">
        <div class="_menu_lmk" v-if="currCardType===10">
          <span @click="onClickPageLink(currCardDetail.links_1)"></span>
          <span @click="onClickPageLink(currCardDetail.links_2)"></span>
          <span @click="onClickPageLink(currCardDetail.links_3)"></span>
        </div>
        <div class="_menu_jxk" v-else-if="currCardType===11">
          <span @click="onClickPageLink(currCardDetail.links_1)"></span>
          <span @click="onClickPageLink(currCardDetail.links_2)"></span>
          <span @click="onClickPageLink(currCardDetail.links_3)"></span>
        </div>

        <div class="_menu" v-else>
          <span @click="onClickPageLink(currCardDetail.banner_link)"></span>
          <span @click="onClickPageLink(currCardDetail.links_1)"></span>
          <span @click="onClickPageLink(currCardDetail.links_2)"></span>
          <span @click="onClickPageLink(currCardDetail.links_3)"></span>
        </div>
      </div>
      <div class="card-content">
        <div v-for="item of currCardArray">

          <div class="_title" v-if="item.title!=''" style="height: 0.68rem;line-height:0.68rem;display: flex; align-items: center; justify-content: space-between;">
            <span class="_main_title">{{ item.title }}</span>
            {{ item.sub_title }}
            <div  style="width: 100%; flex: 1;">
              <div v-if="currCardType===8" style="width:98%;height: 2px;background-color: rgb(239,206,150);margin-top:0.1rem"></div>
              <div v-else style="width:98%;height: 2px;background-color: rgba(255,255,255,1);margin-top:0.1rem"></div>
            </div>
            <span v-if="item.type === 'menus'" class="jump_all" @click="onClickSubMenu()">查看更多></span>
          </div>

          <!-- 衣、食、住、行菜单（1~11） -->
          <template v-if="item.type === 'menus'">
            <div class="_mall">
              <div :class="'_item'+currCardType" v-for="subItem of item.list">
                <img :src="subItem.image_url" alt="" @click="onClickSubMenu(subItem)"><br>{{ subItem.welfare_name }}
              </div>
              <!--              <div class="_item" v-if="currCardType==3">-->
              <!--                <img src="http://pic.eaworks.cn/scbank/uploads/2022728/1314986.png" @click="onClickPageLink('/show_img?id=40851c4d-86d4-4713-be49-be368ca3ef28')"><br>费用减免-->
              <!--              </div>-->
              <!--              <div class="_item">-->
              <!--                <img src="/page/pic/card/sub/sub_11.png" alt="" @click="onClickSubMenu()"><br>全部-->
              <!--              </div>-->
            </div>
          </template>

          <!-- 图片Banner -->
          <template v-if="item.type === 'images'">
            <div class="_img_html">
              <div class="_html" v-for="imageItem of item.images">
                <div class="_list clearfix">
                  <div v-for="subItem of imageItem.list" :class="'_view_'+imageItem.view">
                    <img :src="subItem.img" @click="onClickPageLink(subItem.url)" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 图片Banner -->
          <template v-if="item.type === 'images_res_id'">
            <div class="_img_html">
              <div class="_html" v-for="imageItem of item.images">
                <div class="_list clearfix">
                  <div v-for="subItem of imageItem.list" :class="'_view_'+imageItem.view">
                    <show_img :id="subItem.id"/>
                    <!--                    <img :src="subItem.img" @click="onClickPageLink(subItem.url)" alt=""/>-->
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- 建设者五项福利 -->
          <template v-if="item.type === 'images_jsz'">
            <div class="_img_html">
              <div class="_html" v-for="imageItem of item.images">
                <div class="_list clearfix">
                  <div v-for="subItem of imageItem.list" class="_view_jsz">
                    <img :src="subItem.img" @click="onClickPageLink(subItem.url)" alt=""><span>{{
                      subItem.title
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 尊师卡（自定义） -->
          <template v-if="item.type === 'images_jsz_c'">
            <div class="_jsz_c">
              <div class="_img">
                <img src="../status/img/c_4_1.png" alt="">
              </div>
              <div style=" border: 1px solid #DDB973;border-radius: 0.5rem;margin: -0.25rem 0.15rem 0 0.15rem">
                <div class="_img _links">
                  <img src="http://pic.eaworks.cn/scbank/uploads/2023912/3742172.png" alt="">
                  <router-link class="_l1_1" to="/show_img?id=5cc2f15a-b418-4fde-8788-425fc5965183">补充医疗保险
                  </router-link>
                  <router-link class="_l1_2" to="/show_img?id=1ed26bad-3617-4ed7-8c5d-ac6dd8b79d95">尊师贷款
                  </router-link>
                  <router-link class="_l1_3" to="/show_img?id=d52587a8-4c52-4e58-bf8a-a39b4a5dad5b">费用减免
                  </router-link>
                </div>
                <div class="_img _links">
                  <img src="http://pic.eaworks.cn/scbank/uploads/2023912/6730676.png" alt="">
                  <router-link class="_l2_1" to="/show_img?id=c8a3cfff-02f1-479d-ad2c-b488a0d839ac">人身意外保险
                  </router-link>
                  <router-link class="_l2_2" to="/show_img?id=bc6eddef-792e-4ff9-9c50-9c2fd6b124d9">法律咨询
                  </router-link>
                  <span class="_l2_3"
                        @click="onClickPageLink('https://sd-h5.muyouchuxing.com/?v=cCrZDJ2dP45jDZJmf321YW33yzHe37Qkw#/promotions?id=3388481&mId=581188')">助力权益</span>
                  <!--                  <router-link class="_l2_4" to="/show_img?id=a83b57a8-1ab8-4b27-a4d8-7eb0cd20d542">专属绿色理赔-->
                  <router-link class="_l2_4" to="/showLabel?label=绿色理赔">专属绿色理赔</router-link>
                  <router-link class="_l2_5" to="/show_img?id=8c852c67-572f-4a83-a4da-48ef30f4e508">家庭关爱
                  </router-link>
                  <router-link class="_l2_6" to="/show_img?id=f11c7c8c-bfad-4429-aa03-9ec584213b64">主题活动
                  </router-link>
                </div>
              </div>
              <div class="_img _links">
                <img src="../status/img/c_4_4.png" alt="">
                <router-link class="_l3_more" to="/show_all?card=9">更多权益</router-link>
              </div>
              <div class="show_js_button_box">
                <div class="show_js_button">
                  <div class="show_js_button_img" v-for="subItem of item.list">
                    <img :src="subItem.image_url" width="100%" @click="onClickSubMenu(subItem)" alt=""><br>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="item.type === 'images_lmk'">
            <div class="_jsz_c">
              <div class="_img _links">
                <show_img id="f73230b1-89e7-4bac-98b4-5f867d34c330"/>
                <router-link class="lmk_btn lmk_btn_1" to="/show_img?id=7778e972-41e9-462d-92e2-32be26f40eaa"/>
                <router-link class="lmk_btn lmk_btn_2" to="/show_img?id=afdf592e-11ea-4d23-9d4b-dbd95945c771"/>
                <router-link class="lmk_btn lmk_btn_3" to="/show_img?id=62aafedc-806c-4eca-ac5a-36c249548102"/>
                <router-link class="lmk_btn lmk_btn_4" to="/show_img?id=cbf669cf-8a68-4557-a586-13671ec9abb3"/>
                <router-link class="lmk_btn lmk_btn_5" to="/showLabel?label=联名卡-贵宾休息"/>
                <router-link class="lmk_btn lmk_btn_6" to="/show_img?id=1cab7bb7-eb1d-4930-b94d-065a7d3c9526"/>
                <router-link class="lmk_btn lmk_btn_7" to="/show_img?id=9187f44b-6eaa-4351-a97f-f47cad7545db"/>
                <router-link class="lmk_btn lmk_btn_8" to="/show_img?id=4968bfb4-be0d-488b-b125-aa74cc19d749"/>
                <router-link class="lmk_btn lmk_btn_9" to="/show_img?id=d587d631-8e70-4977-ba5e-cde5358eebe2"/>
                <router-link class="lmk_btn lmk_btn_10" to="/show_img?id=701333ed-faf0-4bea-9d49-79ede69d8d04"/>
                <!--                <div class="lmk_btn lmk_btn_10"-->
                <!--                     @click="onClickPageLink('https://plat-h5.1kahui.cn/#/activity?pageId=8493758369671286784&config=wx&type=1')"></div>-->
              </div>
            </div>
            <div class="_jsz_c">
              <div>
                <div class="_img _links">
                  <show_img id="3b1f222b-ff94-4d9c-bbe0-8e8f31e13c56"/>
                  <div class="show_js_button_img" v-for="(subItem,index) of item.list">
                    <router-link v-if="subItem.id===65" :class="'lmk_btn lmk_btn_'+(index+1)"
                                 :to="'/showLabel?label=金熊猫联名卡-食'"/>
                    <router-link v-else-if="subItem.id===67" :class="'lmk_btn lmk_btn_'+(index+1)"
                                 :to="'/showLabel?label=金熊猫联名卡-购'"/>
                    <router-link v-else :class="'lmk_btn lmk_btn_'+(index+1)"
                                 :to="'/mall?card=' + currCardType+'&type='+subItem.id"/>
                  </div>
                </div>
              </div>
            </div>
            <show_img id="400c61f6-f184-4aad-84b4-35cb3e5ab4e3"/>
          </template>
          <template v-if="item.type === 'images_jxk'">
            <div class="_jsz_c">
              <div>
                <div class="_img _links">
                  <show_img id="909e1e09-58af-4f28-9f17-55c1f3a3df5c"/>
                  <div class="show_js_button_img" v-for="(subItem,index) of item.list">
                    <router-link v-if="subItem.id===75" :class="'jxk_btn jxk_btn_'+(index+1)"
                                 :to="'/showLabel?label=天府匠心卡-食'"/>
                    <router-link v-else-if="subItem.id===77" :class="'jxk_btn jxk_btn_'+(index+1)"
                                 :to="'/showLabel?label=天府匠心卡-购'"/>
                    <router-link v-else :class="'jxk_btn jxk_btn_'+(index+1)"
                                 :to="'/mall?card=' + currCardType+'&type='+subItem.id"/>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- 活动 -->
          <template v-if="item.type === 'activity'">
            <div class="_activity" v-for="sutItem of item.list" :key="item.id">
              <img :src="sutItem.img" @click="onClickPageLink(sutItem.url)">
            </div>
          </template>
          <!-- 活动 -->
          <template v-if="item.type === 'activity_banner'">
            <div class="_activity">
              <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="sutItem of item.list" :key="item.id">
                  <img :src="sutItem.img_url" @click="onClickPageLink(sutItem.link_url)" alt="">
                </van-swipe-item>
              </van-swipe>
            </div>
          </template>
          <!-- 瀑布流 -->
          <template v-if="item.type === 'water'">
            <div class="_water_html">
              <div class="_html clearfix">
                <div class="_left clearfix">
                  <div class="_water_item"
                       @click="onClickPageLink(subItem.jump_type!=''?subItem.jump_type!=null?subItem.jump_type:'/activity?id='+subItem.id:'/activity?id='+subItem.id)"
                       v-for="(subItem, subIndex) of  flowList" v-if="subIndex%2 === 0"><img :src="subItem.flow_img">
                  </div>
                </div>
                <div class="_right clearfix">
                  <div class="_water_item"
                       @click="onClickPageLink(subItem.jump_type!=''?subItem.jump_type!=null?subItem.jump_type:'/activity?id='+subItem.id:'/activity?id='+subItem.id)"
                       v-for="(subItem, subIndex) of  flowList" v-if="subIndex%2 !== 0"><img :src="subItem.flow_img">
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="_right_bar_new_card"
         @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')">
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png">
    </div>
    <div class="notice-box" v-if="showNotice">
      <div class="notice-content">
        <div class="_closed">
          <img src="../status/img/n_closed.png" alt="" @click="showNotice = false">
        </div>
        <div class="_img">
          <img src="../status/img/n_img.png" alt="">
        </div>
      </div>
    </div>
    <div style="height: 2.2rem;margin-top: 20px;">
      <div
        style=" background-color:rgba(128,128,128,0.2);width: 9.6rem; margin:auto;text-align: center; border-radius: 10px;   padding: 3px 3px 3px 3px">
        <div
          style="color: #000;font-size: 12px;text-indent: 2em; opacity: 0.6">
          安逸生活平台展示的所有活动权益均为宣传广告性质，旨在提供信息参考。具体活动详情、参与条件、权益发放等可能存在变动，请您在参与前仔细阅读活动规则，并以官方最新公告或实际活动页面为准。感谢您的理解与配合。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";
import show_img from "./components/show_img.vue"

export default {
  mixins: [page_links],
  components: {show_img},
  data() {
    return {
      pageHeight: 1200,
      cardList: [],
      flowList: [],
      currCardType: 0,
      currCardDetail: null,
      currCardArray: null,
      showNotice: false,
      // 源测试数据
    };
  },

  created() {
    console.log("-> [card.vue] created");
  },

  mounted() {
    console.log("-> [card.vue] mounted");
    this.pageHeight = window.innerHeight || 1200;
    this.currCardType = Number(this.$route.query.type) || 1;
    // this.showNotice = this.currCardType === 1;
    this.getCardDetail();
    // this.getJsInfo();
  },

  methods: {
    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ];
        wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    async getFlowList() {
      // 获取首页卡片列表
      let {error, data} = await this.$ajax.gateway("/api/api_get_flow_merchant", {
        ERROR_BACK: true,
        "METHOD": "get",
        "card_id": this.currCardType
      });
      this.flowList = data;
    },


    async getCardDetail() {
      this.getCardDetailByType();
      this.getFlowList();
    },

    async getCardDetailByType() {
// 获取卡的详情
      let detailRes = await this.$ajax.gateway("/api/api_get_card_detail", {
        "METHOD": "get",
        "card_id": this.currCardType
      });
      // 获取指定卡片的项目
      let {data} = await this.$ajax.gateway("/api/api_get_welfare_by_card", {
        "METHOD": "get",
        "card_id": this.currCardType
      });
      // 获取卡片的banner列表
      let bannerList = await this.$ajax.gateway("/api/admin_get_banner_list", {
        "body": {
          "card_id": this.currCardType
        }
      });

      let arr = [];
      this.currCardDetail = {};
      if (this.currCardType === 1) {
        // 顶部Banner
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=8eccd820-b8d8-4f85-bdeb-3a9a37edda6d",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/showLabel?label=服务指南",
          links_3: "https://h5.vc.nervenets.cn/#/NewMyDiscount?t=0810",
        };
        // 1. 十大服务（游、乐、行、食、购等）
        arr.push({
          type: 'menus',
          title: "十大服务",
          sub_title: "安逸生活 邂逅精彩时光",
          list: data
        })
        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "精选活动",
            list: bannerList.data
          });
        }
        arr.push({
          type: 'water',
          title: "安逸生活",
          sub_title: "文旅一卡通 权益大派对",
          list: []
        })
      } else if (this.currCardType === 2) {
        //金盾卡
        // 顶部Banner
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=e1dfa9b5-1b6c-4e41-b57f-d3d9d8ce3c0f",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/show_img?id=344be1a9-4a96-49a8-afc3-2a0d7373df8f",
          links_3: "https://h5.vc.nervenets.cn/#/NewMyDiscount?t=0810",
        };
        arr.push({
          type: 'activity',
          title: "金盾专属",
          list: [{
            img: "http://pic.eaworks.cn/scbank/uploads/2022924/7605821.png",
            url: "/show_img?id=d06b0141-0ec4-4fce-b54b-56f3aad56918"
          }, {
            img: "http://pic.eaworks.cn/scbank/uploads/2022924/7155357.png",
            url: "/show_img?id=7294f6ee-0e3a-4748-b713-bd1da0916e6a"
          }]
        })
        // 1. 十大服务（游、乐、行、食、购等）
        arr.push({
          type: 'menus',
          title: "暖心惠警",
          sub_title: "十大服务 畅享用卡",
          list: data
        })
        arr.push({
          type: 'activity',
          title: "尊警拥属 暖警套卡",
          list: [{
            img: "http://pic.eaworks.cn/scbank/uploads/2022924/7095243.png",
            url: "/show_img?id=6aae97c8-b702-494a-841e-d8ad6f405530"
          }]
        })

        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "精选活动",
            list: bannerList.data
          });
        }


        arr.push({
          type: 'water',
          title: "金盾卡",
          sub_title: "暖警惠警 从优待警",
          list: []
        })
      } else if (this.currCardType === 3) {
        //拥军尊崇卡
        // 顶部Banner
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=71af9b7e-7ce4-425b-829e-ef242bb8cccb",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/show_img?id=61106950-c6a7-4e9c-937f-6949812786c2",
          links_3: "https://sd-h5.muyouchuxing.com/#/NewMyDiscount?t=0810",
        };
        // 1. 十大服务（游、乐、行、食、购等）
        arr.push({
          type: 'menus',
          title: "八大权益",
          sub_title: "",
          list: data
        });
        arr.push({
          type: 'images',
          title: "一项专属",
          images: [{
            view: 1,
            list: [{
              img: "http://pic.eaworks.cn/scbank/uploads/2022924/1016956.png",
              url: "/show_img?id=0877d4e0-d3ec-4a81-b751-b5af4413799c"
            }]
          }]
        });

        arr.push({
          type: 'images_res_id',
          title: "安心尊享服务",
          images: [{
            view: 1,
            list: [{
              id: "dbaaa2cf-7260-446e-a97d-ced1c0526fa2",
            }]
          }]
        });

        arr.push({
          type: 'images',
          title: "尊军拥属 暖军套卡",
          images: [{
            view: 1,
            list: [{
              img: "http://pic.eaworks.cn/scbank/uploads/2022924/4854740.png",
              url: "/show_img?id=ae5cca80-397c-4116-9cb1-005867b31ff6"
            }]
          }]
        });
        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "精选活动",
            list: bannerList.data
          });
        }
        arr.push({
          type: 'water',
          title: "拥军尊崇卡",
          sub_title: "",
          list: []
        })
      } else if (this.currCardType === 4) {
        //http://pic.eaworks.cn/scbank/uploads/2022920/9285041.png
        //建设者卡
        // 顶部Banner
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=0ff8b644-75c1-4139-a5fb-acb753dd93c3",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/show_img?id=7b863449-8636-472f-97f0-cf067ac3249f",
          links_3: "https://h5.vc.nervenets.cn/#/NewJyfMyDiscount?t=0810",
        };

        arr.push({
          type: 'images_jsz',
          title: "五项专属",
          sub_title: "",//服务贴心 用卡全面
          images: [{
            view: 3,
            list: [{
              img: "http://pic.eaworks.cn/scbank/uploads/2022925/2295397.png",
              title: "返乡优惠",
              url: "/otherMall?id=1"
            }, {
              img: "http://pic.eaworks.cn/scbank/uploads/2022925/4322654.png",
              title: "保险关爱",
              url: "/show_img?id=4634befe-d942-45b5-8bb1-7bc58bc51b16"
            }, {
              img: "http://pic.eaworks.cn/scbank/uploads/2022925/9941600.png",
              title: "法律咨询",
              url: "/show_img?id=5bf3e731-11b3-11ef-b609-02dbc3a99846"
            }, {
              img: "http://pic.eaworks.cn/scbank/uploads/2022925/7908674.png",
              title: "金融服务",
              url: "/otherMall?id=2"
            }, {
              img: "http://pic.eaworks.cn/scbank/uploads/2022925/9061365.png",
              title: "费用减免",
              url: "/show_img?id=44a7eadb-a539-4495-b18c-4af2ec8a0eea"
            }]
          }]
        });

        arr.push({
          type: 'menus',
          title: "十大福利",
          sub_title: "",//服务贴心 用卡全面
          list: data
        })

        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "精选活动",
            list: bannerList.data
          });
        }

        arr.push({
          type: 'water',
          title: "建设者",
          sub_title: "",
          list: []
        })
        //蜀农卡
      } else if (this.currCardType === 8) {
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=7fac205c-4733-4017-ae27-721a82917597",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/show_img?id=57912f76-e912-456e-ae36-616b4d1e65d0",
          links_3: "https://h5.vc.nervenets.cn/#/NewJyfMyDiscount?t=0810",
        };

        arr.push({
          type: 'images_jsz',
          title: "六项专属",
          sub_title: "",//服务贴心 用卡全面
          images: [{
            view: 3,
            list: [{
              img: "https://pic.eaworks.cn/scbank/card8/8-1.png",
              title: " ",
              url: "/showLabel?label=农险补贴"
            }, {
              img: "https://pic.eaworks.cn/scbank/card8/8-2.png",
              title: " ",
              url: "/show_img?id=4dd5d170-f7e6-4dec-b647-1c3459c3280f"
            }, {
              img: "https://pic.eaworks.cn/scbank/card8/8-3.png",
              title: " ",
              url: "/show_img?id=0a3c6338-6e5a-4b06-bcaf-eacbd408e0d6"
            }, {
              img: "https://pic.eaworks.cn/scbank/card8/8-4.png",
              title: " ",
              url: "show_img?id=108cf4b9-bd39-43b4-9bb8-a3733ce91a67"
            }, {
              img: "https://pic.eaworks.cn/scbank/card8/8-5.png",
              title: " ",
              url: "/show_img?id=54e3cf85-3509-4ced-805d-b4ff8fa81d44"
            }, {
              img: "https://pic.eaworks.cn/scbank/card8/8-6.png",
              title: " ",
              url: "/show_img?id=acbab3a5-7680-4bc5-9546-15e22ee49160"
            }]
          }]
        });
        arr.push({
          type: 'menus',
          title: "八大权益",
          sub_title: "",
          list: data
        })
        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "精选活动",
            list: bannerList.data
          });
        }
        arr.push({
          type: 'water',
          title: "蜀农",
          sub_title: "蜀农一卡通 权益大派对",
          list: []
        })

      } else if (this.currCardType === 9) {
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=b05109d3-a0c4-49c7-8519-d9afab63c8c9",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/show_img?id=ebc6caf6-fe8c-4f5c-a189-ac04bc3cd761",
          links_3: "https://sd-h5.muyouchuxing.com/?#/NewMyDiscount?t=0910",
        };

        arr.push({
          type: 'images_jsz_c',
          title: "",
          list: data
        })
        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "",
            list: bannerList.data
          });
        }
        arr.push({
          type: 'water',
          title: "",
          sub_title: "",
          list: []
        })
      } else if (this.currCardType === 10) {
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=ea3bb128-1bd4-4b23-b317-fddd2e2e0fa0",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/show_img?id=41d4dd78-0e3d-45ec-b66e-928cb54fadc1",
          links_3: "https://sd-h5.muyouchuxing.com/?#/NewMyDiscount?t=0222",
        };
        arr.push({
          type: 'images_lmk',
          title: "",
          list: data
        })
        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "精选活动",
            list: bannerList.data
          });
        }
        arr.push({
          type: 'water',
          title: "",
          sub_title: "",
          list: []
        })
      } else if (this.currCardType === 11) {
        this.currCardDetail = {
          banner: detailRes.data.head_img,
          head_link: detailRes.data.index_img_link,
          banner_link: "/show_img?id=070919d7-a36f-447b-8a75-a7750a772613",
          menu_bg: detailRes.data.nav_img,
          links_1: "/branches",
          links_2: "/show_img?id=99574693-586e-463f-87e4-782e7a26b196",
          links_3: "https://sd-h5.muyouchuxing.com/?#/NewMyDiscount?t=0411",
        };
        arr.push({
          type: 'images_jxk',
          title: "",
          list: data
        });
        if (bannerList.data.length > 0) {
          // 2. 精选活动
          arr.push({
            type: 'activity_banner',
            title: "精选活动",
            list: bannerList.data
          });
        }
        arr.push({
          type: 'water',
          title: "",
          sub_title: "",
          list: []
        });
      }
      this.currCardArray = arr;
    },

    onClickItem({url}) {
      this.$router.push(url);
    },

    onClickCardItem(item) {
      if (this.currCardDetail.id !== item.id) {
        let url = "/card?type=" + item.id
        this.$router.push(url);
        window.location.reload()
      }
    },

    onClickCardMenu(val) {
      console.log("-> onClickCardMenu", val);
    },

    onClickSubMenu(item) {
      if (item) {
        let url = "/mall?card=" + this.currCardType + "&type=" + item.id;
        if (item.id === 4) {
          url = "/showLabel?label=文旅一卡通-食";
        } else if (item.id === 6) {
          url = "/showLabel?label=文旅一卡通-购";
        } else if (item.id === 90) {
          url = "/showLabel?label=建设者卡-食";
        } else if (item.id === 92) {
          url = "/showLabel?label=建设者卡-购";
        } else if (item.id === 105) {
          url = "/showLabel?label=尊师卡-购";
        } else if (item.id === 111) {
          url = "/showLabel?label=尊师卡-食";
        } else if (item.id === 17) {
          url = "/showLabel?label=金盾卡-购";
        } else if (item.id === 15) {
          url = "/showLabel?label=金盾卡-食";
        } else if (item.id === 27) {
          url = "/showLabel?label=拥军卡-购";
        } else if (item.id === 25) {
          url = "/showLabel?label=拥军卡-食";
        }
        this.$router.push(url);
      } else {
        // 全部
        this.$router.push("/show_all?card=" + this.currCardType);
      }
    },
    async ajaxGetCardData() {
      // 获取首页卡片列表
      let {error, data} = await this.$ajax.gateway("/api/api_get_card_list", {
        ERROR_BACK: true,
        "METHOD": "get"
      });
      this.homeList = data;
    },

  }
};
</script>

<style lang="less" scoped>
.notice-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;

  .notice-content {
    padding: 10vh 4% 0;
    position: relative;

    ._closed {
      position: absolute;
      top: 10vh;
      right: 4%;
      z-index: 201;

      img {
        width: 1.6rem;
        margin-top: -0.1rem;
        margin-right: -0.05rem;
      }
    }

    ._img {
      position: relative;
      z-index: 200;

      img {
        max-width: 100%;
      }
    }
  }

}

.card-home {
  max-width: 750px;
  margin: 0 auto;
  position: relative;
  background: #fff;

  .left-menu {
    width: 2.4rem;
    height: 100%;
    background: #f0f0f0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    ._logo {
      padding: 0.8rem 0 0.4rem;
      text-align: center;

      img {
        width: 68%;
      }
    }

    ._menu {
      ._item {
        padding: 0.35rem 0;
        font-size: 0.35rem;
        position: relative;
        margin-bottom: 0.2rem;
        text-align: left;
        text-indent: 0.35rem;
        font-weight: bold;

        &._current {
          background: #fff;
          color: #f86a4b;

          &:before, &:after {
            content: ' ';
            width: 0.4rem;
            height: 0.4rem;
            position: absolute;
            right: 0;
          }

          &:before {
            top: -0.4rem;
            background: url("../status/img/left_menu_current_1.png") no-repeat right center;
            background-size: auto 100%;
          }

          &:after {
            bottom: -0.4rem;
            background: url("../status/img/left_menu_current_2.png") no-repeat right center;
            background-size: auto 100%;
          }
        }

        &._disabled {
          color: #d0d0d0;
          position: relative;

          span {
            display: inline-block;
            width: 1.2rem;
            height: 0.35rem;
            background: url("../status/img/left_menu_disabled.png") no-repeat left center;
            background-size: auto 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .btn-back {
    position: fixed;
    bottom: 1.5rem;
    left: -5px;
    z-index: 11;

    img {
      display: block;
      width: 2.2rem;
    }
  }
}

.card-wrapper {
  padding: 0 0 0 0;
  min-height: 10rem;

  .card-banner {
    img {
      display: block;
      width: 100%;
    }
  }

  .card-menu {
    padding: 0.2rem 0.2rem;
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    ._menu {
      width: 100%;
      position: absolute;
      top: 0.2rem;
      left: 0;

      span {
        display: block;
        width: 25%;
        height: 1.4rem;
        float: left;
      }
    }

    ._menu_lmk {
      width: 100%;
      position: absolute;
      top: 0.2rem;
      left: 0;

      span {
        display: block;
        width: 33%;
        height: 1rem;
        float: left;
      }
    }

    ._menu_jxk {
      width: 100%;
      position: absolute;
      top: 0.2rem;
      left: 0;

      span {
        display: block;
        width: 33%;
        height: 2rem;
        float: left;
      }
    }


  }

  .card-content {
    .show_js_button_box {
      border: 1px solid #DDB973;
      border-radius: 0.5rem;
      margin: -0.25rem 0.15rem 0 0.15rem;
      overflow: hidden;
      padding-left: 0.5rem;

      .show_js_button {
        padding-top: 0.3rem;
        width: 100%;
        height: 100%;

        .show_js_button_img {
          display: inline-block;
          width: 20%;

          img {
            width: 1.5rem;
          }
        }
      }


    }

    ._title {
      font-size: 0.28rem;
      color: #c2c2c2;
      padding: 0.2rem 0.3rem 0.1rem;

      ._main_title {
        display: inline-block;
        line-height: 0.6rem;
        padding: 0 0.3rem 0 0;
        background: url("../status/img/card_title_bg.png") no-repeat right center;
        background-size: auto 100%;
        font-size: 0.50rem;
        letter-spacing: 0.1rem;
        color: #000;
        margin-right: 0.15rem;
      }

      .jump_all {
        text-align: center;
        width: 1.8rem;
        background: #f9b48b;
        color: #fff;
        font-size: 0.3rem;
        position: absolute;
        right: 0.4rem;
        padding-left: 0.1rem;
        border-radius: 1rem;
        line-height: 0.6rem;
      }
    }

    ._mall {
      padding: 0.2rem 0.2rem 0;

      ._item1 {
        display: inline-block;
        width: 20%;
        text-align: center;
        font-size: 0.4rem;
        line-height: 1.7em;
        margin-bottom: 0.3rem;

        img {
          width: 90%;
        }
      }


      ._item2 {
        display: inline-block;
        width: 20%;
        text-align: center;
        font-size: 0.4rem;
        line-height: 1.7em;
        margin-bottom: 0.3rem;

        img {
          width: 90%;
        }
      }

      ._item3 {
        display: inline-block;
        width: 25%;
        text-align: center;
        font-size: 0.4rem;
        line-height: 1.7em;
        margin-bottom: 0.3rem;

        img {
          width: 90%;
        }
      }

      ._item4 {
        display: inline-block;
        width: 25%;
        text-align: center;
        font-size: 0.4rem;
        line-height: 1.7em;
        margin-bottom: 0.3rem;

        img {
          width: 90%;
        }
      }

      ._item8 {
        display: inline-block;
        width: 25%;
        text-align: center;
        font-size: 0.4rem;
        //line-height: 1.7em;
        //margin-bottom: 0.3rem;
        img {
          width: 90%;
        }
      }
    }

    ._activity {
      padding: 0.2rem 0.25rem 0.1rem;

      img {
        display: block;
        width: 100%;
        margin-bottom: 0.2rem;
      }
    }

    ._activity2 {
      //padding: 0.2rem 0.25rem 0.1rem;
      img {
        display: block;
        width: 100%;
        margin-bottom: 0.2rem;
      }
    }

    ._jsz_c {
      ._img {
        img {
          display: block;
          width: 100%;
        }
      }

      ._links {
        position: relative;

        .lmk_btn {
          display: block;
          width: 1.8rem;
          height: 1.8rem;
          font-size: 12px;
          position: absolute;
        }

        .lmk_btn_1 {

          top: 1.5rem;
          left: 0.3rem;
        }

        .lmk_btn_2 {
          top: 1.5rem;
          left: 2.2rem;
        }

        .lmk_btn_3 {
          top: 1.5rem;
          left: 4.1rem;
        }

        .lmk_btn_4 {
          top: 1.5rem;
          left: 6rem;
        }

        .lmk_btn_5 {
          top: 1.5rem;
          left: 7.9rem;
        }

        .lmk_btn_6 {
          top: 3.4rem;
          left: 0.3rem;
        }

        .lmk_btn_7 {
          top: 3.4rem;
          left: 2.2rem;
        }

        .lmk_btn_8 {
          top: 3.4rem;
          left: 4.1rem;
        }

        .lmk_btn_9 {
          top: 3.4rem;
          left: 6rem;
        }

        .lmk_btn_10 {
          top: 3.4rem;
          left: 7.9rem;
        }

        .jxk_btn {
          display: block;
          width: 2.2rem;
          height: 2.2rem;
          font-size: 12px;
          position: absolute;
        }

        .jxk_btn_1 {
          top: 1rem;
          left: 0.2rem;
        }

        .jxk_btn_2 {
          top: 1rem;
          left: 2.55rem;
        }

        .jxk_btn_3 {

          top: 1rem;
          left: 4.9rem;
        }

        .jxk_btn_4 {

          top: 1rem;
          left: 7.2rem;
        }

        .jxk_btn_5 {
          top: 3.5rem;
          left: 0.2rem;
        }

        .jxk_btn_6 {
          top: 3.5rem;
          left: 2.55rem;
        }

        .jxk_btn_7 {
          top: 3.5rem;
          left: 4.9rem;
        }

        .jxk_btn_8 {
          top: 3.5rem;
          left: 7.2rem;
        }


        ._l1_1, ._l1_2, ._l1_3 {
          display: block;
          width: 33%;
          height: 100%;
          font-size: 12px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }

        ._l1_2 {
          left: 33.3%;
        }

        ._l1_3 {
          left: 66.6%;
        }

        ._l2_1, ._l2_2, ._l2_3, ._l2_4, ._l2_5, ._l2_6 {
          display: block;
          width: 33%;
          height: 50%;
          font-size: 12px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }

        ._l2_4, ._l2_5, ._l2_6 {
          top: auto;
          bottom: 0;
        }

        ._l2_2, ._l2_5 {
          left: 33.3%;
        }

        ._l2_3, ._l2_6 {
          left: 66.6%;
        }

        ._l3_more {
          display: block;
          width: 24%;
          height: 100%;
          font-size: 12px;
          position: absolute;
          top: 0;
          right: 0;
          opacity: 0;
        }

        ._l4_1, ._l4_2, ._l4_3, ._l4_4, ._l4_5, ._l4_6, ._l4_7, ._l4_8, ._l4_9, ._l4_10 {
          display: block;
          width: 20%;
          height: 50%;
          font-size: 12px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }

        ._l4_6, ._l4_7, ._l4_8, ._l4_9, ._l4_10 {
          top: auto;
          bottom: 0;
        }

        ._l4_2, ._l4_7 {
          left: 20%;
        }

        ._l4_3, ._l4_8 {
          left: 40%;
        }

        ._l4_4, ._l4_9 {
          left: 60%;
        }

        ._l4_5, ._l4_10 {
          left: 80%;
        }
      }
    }

    ._img_html {
      padding: 0.2rem 0.25rem 0.1rem;

      ._html {
        ._list {
          ._view_1, ._view_2, ._view_3, ._view_4, ._view_5, ._view_jsz {
            float: left;
            margin-bottom: 0.2rem;
            text-align: center;

            img {
              display: block;
              width: 100%;
            }
          }

          ._view_1 {
            width: 100%;
          }

          ._view_2 {
            width: 50%;
          }

          ._view_3 {
            width: 33.3%;
          }

          ._view_jsz {
            font-size: 0.4rem;
            padding-left: 0.2rem;
            padding-right: 0.1rem;
            width: 30%;
          }

          ._view_jsz span {
            line-height: 0.8rem;
          }

          ._view_4 {
            width: 25%;
          }

          ._view_5 {
            width: 20%;
          }

        }
      }
    }

    ._water_html {
      ._html {
        ._left, ._right {
          width: 50%;
          float: left;

          ._water_item {
            float: left;
            margin-bottom: -0.2rem;

            img {
              display: block;
              width: 100%;
            }
          }
        }

        ._left {
          margin-left: 0.1rem;
        }

        ._right {
          margin-left: -0.1rem;
        }
      }
    }

  }
}

._right_bar_new_card {
  position: fixed;
  top: 0.3rem;
  right: 0rem;

  img {
    height: 2.7rem;
  }
}
</style>
